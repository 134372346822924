export default function FooterCopyright() {
  return (
    <div
      className={`flex w-full h-58px flex-col items-center justify-center border-t border-white`}>
      <div className="text-13 font-text text-white">
        Copyright © 2020 - RealTech247. All Rights Reserved.
      </div>
    </div>
  );
}
